import { parseStringToId } from "@applied-ai/utils";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { AlertColor, AlertProps, SxProps, useTheme } from "@mui/material";
import React from "react";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGFM from "remark-gfm";
import remarkToc from "remark-toc";
import { Alert, StyledHTML, Typography } from "../../atoms";

enum MarkdownAlertType {
  INFO = "Info",
  SUCCESS = "Success",
  WARNING = "Warning",
  ERROR = "Error",
}

export const MarkdownComponent: React.FC<{
  children: string | undefined;
  sx?: SxProps;
}> = ({ children, sx }) => {
  const theme = useTheme();

  const handleAlertStyles = (
    alertType: MarkdownAlertType
  ): {
    severity?: AlertColor;
    backgroundColor: string;
    iconColor: string;
  } => {
    switch (alertType) {
      case MarkdownAlertType.INFO:
        return {
          severity: "info",
          backgroundColor: "#EDF4FF",
          iconColor: "chartPalette.11",
        };
      case MarkdownAlertType.SUCCESS:
        return {
          severity: "success",
          backgroundColor: theme.palette.green[50],
          iconColor: "green.700",
        };
      case MarkdownAlertType.WARNING:
        return {
          severity: "warning",
          backgroundColor: theme.palette.yellow[50],
          iconColor: "yellow.700",
        };
      case MarkdownAlertType.ERROR:
        return {
          severity: "error",
          backgroundColor: theme.palette.red[50],
          iconColor: "red.700",
        };
      default:
        return {
          severity: undefined,
          backgroundColor: theme.palette.grey[100],
          iconColor: "",
        };
    }
  };

  return (
    <StyledHTML
      sx={{
        paddingBlock: "16px",
        "*": { color: "inherit", m: 0, whiteSpace: "pre-line" },
        color: "grey.700 !important",
        overflow: "hidden",
        width: "100%",
        whiteSpace: "pre-line",
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        wordBreak: "break-word",
        ...sx,
      }}
    >
      <Markdown
        remarkPlugins={[remarkGFM, remarkToc]}
        rehypePlugins={[rehypeRaw]}
        components={{
          h1: ({ node, ...props }) => (
            <h1
              id={parseStringToId(props.children as string)}
              style={{
                fontSize: "24px",
                lineHeight: "32px",
                fontWeight: 600,
              }}
            >
              {props.children}
            </h1>
          ),
          h2: ({ node, ...props }) => (
            <h2
              id={parseStringToId(props.children as string)}
              style={{ fontSize: "20px", lineHeight: "28px", fontWeight: 600 }}
            >
              {props.children}
            </h2>
          ),
          h3: ({ node, ...props }) => (
            <h3
              id={parseStringToId(props.children as string)}
              style={{ fontSize: "16px", lineHeight: "24px", fontWeight: 600 }}
            >
              {props.children}
            </h3>
          ),
          p: ({ node, ...props }) => (
            <Typography
              sx={{ fontSize: "14px", lineHeight: "20px", fontWeight: 400 }}
            >
              {props.children}
            </Typography>
          ),
          strong: ({ node, ...props }) => (
            <strong
              style={{ fontSize: "14px", lineHeight: "20px", fontWeight: 600 }}
            >
              {props.children}
            </strong>
          ),
          a: ({ node, ...props }) => (
            <a
              href={props.href ? props.href : ""}
              target={props.href?.includes("http") ? `_blank` : `_self`}
            >
              {props.children}
            </a>
          ),
          img: ({ node, ...props }) => (
            <img
              {...(props as React.ImgHTMLAttributes<unknown>)}
              width="100%"
              alt={props.alt || ""}
              fetchPriority="low"
              loading="lazy"
            />
          ),
          blockquote: ({ node, ...props }) => {
            // In this case, `props.children` contains dynamically generated elements from Markdown.
            // We need to navigate through child elements based on their specific placement to reach the desired content.
            // (`props.children[1]`) contains the information we are interested in (e.g., alert type)
            // The first child of this node: (`children[0]`) further contains more detailed data
            // and its children ("props.children") is the actual content we need to decide what type of alert we want to display.

            const alertType =
              Array.isArray(props.children) && props.children[1]
                ? props.children[1]?.props?.children?.[0]?.props?.children
                : null;

            const contentToDisplay =
              Array.isArray(props.children) && props.children[1];
            const alertStyle = handleAlertStyles(alertType);

            return (
              <Alert
                severity={alertStyle.severity || ("" as AlertColor)}
                icon={
                  !!alertStyle.severity && (
                    <InfoOutlinedIcon
                      sx={{
                        position: "relative",
                        bottom: "2px",
                        right: "2px",
                        width: "20px",
                        height: "20px",
                      }}
                    />
                  )
                }
                {...(props as AlertProps)}
                sx={{
                  background: `${alertStyle.backgroundColor} !important`,
                  borderRadius: "2px",
                  borderLeft: "4px solid",
                  borderTop: 0,
                  borderRight: 0,
                  borderBottom: 0,
                  padding: "12px 16px",

                  "& .MuiAlert-message": {
                    padding: 0,
                    width: "100%",
                  },

                  "& .MuiAlert-icon": {
                    width: 0,
                    height: 0,
                    padding: 0,
                    position: "relative",
                    left: "8px",

                    svg: {
                      color: alertStyle.iconColor,
                    },
                  },

                  p: {
                    strong: {
                      color: theme.palette.grey[900],
                      fontWeight: "400 !important",
                      position: "relative",
                      left: alertStyle.severity ? "24px" : "0px",
                      bottom: "3px",
                    },
                  },
                }}
              >
                {contentToDisplay ?? ""}
              </Alert>
            );
          },
          pre: ({ node, ...props }) => (
            <pre
              {...props}
              style={{
                background: theme.palette.grey[100],
                padding: "12px 16px",
                borderRadius: "2px",
                lineHeight: "20px",
              }}
            />
          ),
          details: ({ node, ...props }) => <details>{props.children}</details>,
          summary: ({ node, ...props }) => <summary {...props} />,
        }}
      >
        {children}
      </Markdown>
    </StyledHTML>
  );
};
